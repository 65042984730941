.new_pricing_table_2020_12 .container.faq_container {
    padding: 50px 0 0 0 !important;
    width: auto !important
}

.new_pricing_table_2020_12 .container.faq_container .faq_big_title {
    font-weight: 700;
    font-size: 2em;
    text-align: center
}

.new_pricing_table_2020_12 .container.faq_container .faq_item .answer {
    line-height: 1.8em
}

.new_pricing_table_2020_12 .container.faq_container .faq_item .answer a[target="_blank"]::after {
    content: " \002197"
}

.new_pricing_table_2020_12 .container.faq_container .faq_item .answer table.table {
    margin-bottom: 1em
}

.new_pricing_table_2020_12 .container.faq_container .faq_item .answer img, .new_pricing_table_2020_12 .container.faq_container .faq_item .answer p {
    margin-bottom: 1em
}

.new_pricing_table_2020_12 .container.faq_container .faq_item .answer img:last-child, .new_pricing_table_2020_12 .container.faq_container .faq_item .answer p:last-child {
    margin-bottom: 0
}

.new_pricing_table_2020_12 .container.faq_container .faq_item .answer img {
    display: block;
    border: 1px solid #000;
    max-width: 60%
}

.new_pricing_table_2020_12 .container.faq_container .faq_group {
    text-align: left;
    margin-top: 40px
}

.new_pricing_table_2020_12 .container.faq_container .faq_group .faq_section_title {
    font-weight: 600;
    font-size: 24px;
    padding-bottom: 15px;
    border-bottom: 1px solid #d1d1d6;
    padding-top: 50px
}

.new_pricing_table_2020_12 .container.faq_container .faq_group .faq_section_title:first-child {
    padding-top: 0
}

.new_pricing_table_2020_12 .container.faq_container .faq_group .faq_item {
    border-bottom: 1px solid #d1d1d6;
    padding: 15px 0
}

.new_pricing_table_2020_12 .container.faq_container .faq_group .faq_item .question {
    font-weight: 600;
    font-size: 18px;
    color: #007aff;
    cursor: pointer
}

.new_pricing_table_2020_12 .container.faq_container .faq_group .faq_item .answer {
    padding-top: 3px;
    display: none
}

.new_pricing_table_2020_12 .button.is-static.fixed_api_credits {
    background: #fff;
    color: #363636
}

.new_pricing_table_2020_12 .pricing_table_container {
    border: 1px solid #d1d1d6;
    border-color: #d1d1d6;
    background: #fff
}

.new_pricing_table_2020_12 .low_volume_plan_notice {
    padding-top: 2em;
    color: #8e8e93;
    text-align: center
}

.new_pricing_table_2020_12 .custom_plan_notice {
    font-size: 1.3em;
    padding: 0 1em;
    padding-top: 1em;
    text-align: center;
    font-weight: 700
}

.new_pricing_table_2020_12 table.pricing_table_table {
    table-layout: fixed;
    border-collapse: collapse;
    border: hidden;
    width: 100%
}

.new_pricing_table_2020_12 table.pricing_table_table a.button {
    box-shadow: 1px 2px 2px rgba(0, 0, 0, .1);
    padding-right: .75em;
    padding-left: .75em
}

.new_pricing_table_2020_12 table.pricing_table_table tr.plans_row td {
    border-top: none
}

@media only screen and (max-width: 900px) {
    .new_pricing_table_2020_12 table.pricing_table_table tr.section_row {
        display: none
    }
}

.new_pricing_table_2020_12 table.pricing_table_table tr.section_row td {
    border: none
}

.new_pricing_table_2020_12 table.pricing_table_table tr.section_row td.section_title {
    padding: 10px 20px;
    font-size: .9em;
    position: relative;
    font-weight: 700
}

.new_pricing_table_2020_12 table.pricing_table_table td {
    border: 1px solid #efeff4
}

.new_pricing_table_2020_12 table.pricing_table_table td.noborder {
    border: none
}

.new_pricing_table_2020_12 table.pricing_table_table td.intro {
    padding: 20px;
    padding-top: 24px
}

.new_pricing_table_2020_12 table.pricing_table_table td.intro p {
    font-size: .9em;
    padding-bottom: 5px;
    color: #8e8e93
}

.new_pricing_table_2020_12 table.pricing_table_table td.intro p strong {
    font-weight: 700;
    color: #000
}

.new_pricing_table_2020_12 table.pricing_table_table td.feature_detail, .new_pricing_table_2020_12 table.pricing_table_table td.feature_title {
    padding: 8px 20px;
    vertical-align: middle
}

.new_pricing_table_2020_12 table.pricing_table_table td.feature_title {
    font-size: .9em;
    position: relative;
    font-weight: 700
}

.new_pricing_table_2020_12 table.pricing_table_table td.feature_title img {
    margin-top: 1px;
    position: absolute;
    width: 20px;
    display: block;
    right: 10px;
    cursor: pointer
}

.new_pricing_table_2020_12 table.pricing_table_table td.feature_title em {
    border-bottom: 1px dotted rgba(0, 0, 0, .5);
    position: relative;
    font-style: normal;
    cursor: pointer;
    font-weight: 400;
    opacity: .5
}

.new_pricing_table_2020_12 table.pricing_table_table td.feature_title span {
    padding-right: 30px
}

@media only screen and (max-width: 900px) {
    .new_pricing_table_2020_12 table.pricing_table_table .api_quota .field.is-grouped.is-grouped-centered {
        justify-content: flex-start
    }
}

.new_pricing_table_2020_12 table.pricing_table_table td.feature_detail {
    text-align: center
}

.new_pricing_table_2020_12 table.pricing_table_table td.feature_detail .control {
    padding: 5px 0
}

.new_pricing_table_2020_12 table.pricing_table_table td.feature_detail.negative {
    color: #d1d1d6
}

.new_pricing_table_2020_12 table.pricing_table_table td.feature_detail .feature_availability {
    font-size: 1.2em
}

@media only screen and (max-width: 900px) {
    .new_pricing_table_2020_12 table.pricing_table_table td.feature_detail .feature_availability {
        font-size: 1em
    }
}

.new_pricing_table_2020_12 table.pricing_table_table td.feature_detail .feature_availability.positive {
    background: #1dab45;
    font-size: 12px;
    font-weight: 700;
    width: 20px;
    height: 20px;
    color: #fff;
    line-height: 20px;
    margin: 5px auto;
    border-radius: 50px
}

@media only screen and (max-width: 900px) {
    .new_pricing_table_2020_12 table.pricing_table_table td.feature_detail .feature_availability.positive {
        margin: 0;
        text-align: center;
        color: #1dab45;
        background: 0 0;
        width: auto;
        height: auto;
        display: inline;
        line-height: auto;
        font-size: 1em
    }
}

@media only screen and (max-width: 900px) {
    .new_pricing_table_2020_12 table.pricing_table_table {
        display: block
    }

    .new_pricing_table_2020_12 table.pricing_table_table tbody, .new_pricing_table_2020_12 table.pricing_table_table td, .new_pricing_table_2020_12 table.pricing_table_table th, .new_pricing_table_2020_12 table.pricing_table_table thead, .new_pricing_table_2020_12 table.pricing_table_table tr {
        display: block
    }

    .new_pricing_table_2020_12 table.pricing_table_table td.intro, .new_pricing_table_2020_12 table.pricing_table_table tr.volume_level {
        display: none
    }

    .new_pricing_table_2020_12 table.pricing_table_table td {
        border-top: none;
        border-right: none;
        border-left: none
    }

    .new_pricing_table_2020_12 table.pricing_table_table td.feature_title {
        text-align: left;
        font-weight: 700;
        padding-right: 10px;
        padding-left: 10px;
        padding: 10px;
        font-size: 1.1em;
        margin: -1px 0
    }

    .new_pricing_table_2020_12 table.pricing_table_table td.feature_title em {
        font-weight: 700
    }

    .new_pricing_table_2020_12 table.pricing_table_table td.feature_title img {
        background: #fff;
        padding: 3px;
        border-radius: 5px;
        margin-top: 3px;
        left: 10px
    }

    .new_pricing_table_2020_12 table.pricing_table_table td.feature_title span {
        padding-left: 28px
    }

    .new_pricing_table_2020_12 table.pricing_table_table tr.feature_row td.feature_detail {
        padding-left: 50%;
        position: relative;
        text-align: left
    }

    .new_pricing_table_2020_12 table.pricing_table_table tr.feature_row td:before {
        position: absolute;
        left: 10px;
        text-align: left;
        width: 45%;
        white-space: nowrap
    }

    .new_pricing_table_2020_12 table.pricing_table_table tr.feature_row td:nth-of-type(2):before {
        content: "Automate Plan"
    }

    .new_pricing_table_2020_12 table.pricing_table_table tr.feature_row td:nth-of-type(3):before {
        content: "Scale Plan"
    }

    .new_pricing_table_2020_12 table.pricing_table_table tr.feature_row td:nth-of-type(4):before {
        content: "Enterprise Plan"
    }
}

.new_pricing_table_2020_12 .price_band_header {
    text-align: center;
    color: #000;
    padding: 15px 0;
    font-weight: 700
}

.new_pricing_table_2020_12 .new_price_plan {
    text-align: center;
    position: relative
}

.new_pricing_table_2020_12 .new_price_plan.plan_header {
    padding: 20px 0
}

@media only screen and (max-width: 900px) {
    .new_pricing_table_2020_12 .new_price_plan.plan_header {
        padding-bottom: 30px
    }
}

.new_pricing_table_2020_12 .new_price_plan .highlighted_title {
    position: absolute;
    z-index: 10;
    text-align: center;
    top: -15px;
    width: 100%
}

.new_pricing_table_2020_12 .new_price_plan .highlighted_title span {
    background: #007aff;
    color: #fff;
    font-size: 8px;
    line-height: 1em;
    padding: 3px 7px;
    border-radius: 8px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600
}

.new_pricing_table_2020_12 .new_price_plan .plan_icon {
    height: 70px;
    width: 70px;
    background: gold;
    border-radius: 10px;
    margin: 0 auto
}

.new_pricing_table_2020_12 .new_price_plan .plan_name {
    font-size: 1.2em;
    font-weight: 700
}

.new_pricing_table_2020_12 .new_price_plan .plan_price {
    font-size: 2.8em;
    font-weight: 700;
    padding-bottom: 5px
}

.new_pricing_table_2020_12 .new_price_plan .plan_price .the_price_container {
    position: relative
}

.new_pricing_table_2020_12 .new_price_plan .plan_price .the_price_container .the_dollar_sign {
    position: absolute;
    top: 2px;
    left: -13px;
    font-size: 45%;
    font-weight: 400
}

.new_pricing_table_2020_12 .new_price_plan .plan_price .the_price_container .the_dollar_sign.adjust {
    left: -10px
}

.new_pricing_table_2020_12 .new_price_plan .plan_price .per {
    margin-top: -8px;
    font-size: 12px;
    font-weight: 400;
    opacity: .5;
    display: block
}

.new_pricing_table_2020_12 .new_price_plan .plan_cta {
    padding: 15px 0
}

.new_pricing_table_2020_12 .new_price_plan .plan_desc {
    padding: 0 2vw;
    font-weight: 500
}

@media only screen and (max-width: 900px) {
    .new_pricing_table_2020_12 .new_price_plan .plan_desc {
        padding: 0 25px
    }
}