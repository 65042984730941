.container.jsx-1831890883 {
    position: relative;
    min-width: 582px;
    max-width: 1024px;
    padding: 56px 56px;
}

.container.jsx-1831890883 .watermark {
    fill-opacity: .75;
    position: absolute;
    z-index: 2;
    bottom: -webkit-calc(56px + 16px);
    bottom: -moz-calc(56px + 16px);
    bottom: calc(56px + 16px);
    right: -webkit-calc(56px + 16px);
    right: -moz-calc(56px + 16px);
    right: calc(56px + 16px)
}

.container.jsx-1831890883 .container-bg.jsx-1831890883 {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
}

.container.jsx-1831890883 .white.jsx-1831890883 {
    background: #fff;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
}

.container.jsx-1831890883 .bg.jsx-1831890883 {
    background: rgba(171, 184, 195, 1);
    background-size: auto;
    background-repeat: repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
}

.container.jsx-1831890883 .alpha.jsx-1831890883 {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==)
}

.container.jsx-1831890883 .CodeMirror-gutters {
    background-color: unset;
    border-right: none
}

.container.jsx-1831890883 .CodeMirror__container {
    min-width: inherit;
    position: relative;
    z-index: 1;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    box-shadow: 0 20px 68px rgba(0, 0, 0, 0.55);
}

.container.jsx-1831890883 .CodeMirror__container .CodeMirror {
    /*height: auto;*/
    min-width: inherit;
    padding: 18px 18px;
    padding-left: 12px;
    border-radius: 5px;
    font-family: Hack, monospace !important;
    font-size: 14px;
    line-height: 133%;
    -webkit-font-variant-ligatures: contextual;
    -moz-font-variant-ligatures: contextual;
    font-variant-ligatures: contextual;
    -webkit-font-feature-settings: "calt" 1;
    -moz-font-feature-settings: "calt" 1;
    font-feature-settings: "calt" 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.container.jsx-1831890883 .CodeMirror-scroll, .container.jsx-1831890883 .CodeMirror-hscrollbar {
    overflow: hidden !important
}

.container.jsx-1831890883 .window-theme__sharp > .CodeMirror {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px
}

.container.jsx-1831890883 .window-theme__bw > .CodeMirror {
    border: 2px solid #fff
}

.container.jsx-1831890883 .window-controls + .CodeMirror__container > .CodeMirror {
    padding-top: 48px
}

.container.jsx-1831890883 .CodeMirror-linenumber {
    cursor: pointer
}

.container.jsx-1831890883 .CodeMirror-cursor {
    visibility: hidden;
}

@media (max-width: 768px) {
    .container.jsx-1831890883 [contenteditable="true"] {
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text
    }

    .container.jsx-1831890883 {
        max-width: 480px
    }
}

.section.jsx-1831890883, .export-container.jsx-1831890883 {
    height: 100%;
    /*display: -webkit-box;*/
    /*display: -webkit-flex;*/
    /*display: -moz-box;*/
    /*display: -ms-flexbox;*/
    /*display: flex;*/
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    max-width: 100%
}