.CodeMirror.cm-s-synthwave-84 {
    font-family: Menlo,Consolas,'DejaVu Sans Mono',monospace;
    font-weight: 350;
    font-size: 18px;
    color: #b6b1b1;
    background-color: #2b213a
}

.cm-s-synthwave-84 .CodeMirror-selected {
    background-color: #1f172e!important
}

.cm-s-synthwave-84 .CodeMirror-gutter,.cm-s-synthwave-84 .CodeMirror-gutters {
    border: none;
    background-color: #011627
}

.cm-s-synthwave-84 .CodeMirror-linenumber,.cm-s-synthwave-84 .CodeMirror-linenumbers {
    color: #6d77b3!important;
    background-color: transparent
}

.cm-s-synthwave-84 .CodeMirror-lines {
    color: #b6b1b1!important;
    background-color: transparent
}

.cm-s-synthwave-84 .CodeMirror-cursor {
    border-left: 2px solid #fa7b73!important
}

.cm-s-synthwave-84 .CodeMirror-matchingbracket,.cm-s-synthwave-84 .CodeMirror-matchingtag {
    border-bottom: 2px solid #c792ea;
    color: #b6b1b1!important;
    background-color: transparent
}

.cm-s-synthwave-84 .CodeMirror-nonmatchingbracket {
    border-bottom: 2px solid #fa7b73;
    color: #b6b1b1!important;
    background-color: transparent
}

.cm-s-synthwave-84 .CodeMirror-foldgutter,.cm-s-synthwave-84 .CodeMirror-foldgutter-folded,.cm-s-synthwave-84 .CodeMirror-foldgutter-open,.cm-s-synthwave-84 .CodeMirror-foldmarker {
    border: none;
    text-shadow: none;
    color: #5c6370!important;
    background-color: transparent
}

.cm-s-synthwave-84 .CodeMirror-activeline-background {
    background-color: #2b213a
}

.cm-s-synthwave-84 .cm-quote {
    color: #6d77b3;
    font-style: italic
}

.cm-s-synthwave-84 .cm-negative {
    color: #fff5f6;
    text-shadow: 0 0 2px #000,0 0 10px #fc1f2c75,0 0 5px #fc1f2c75,0 0 25px #fc1f2c75
}

.cm-s-synthwave-84 .cm-positive {
    color: #fff5f6;
    text-shadow: 0 0 2px #000,0 0 10px #fc1f2c75,0 0 5px #fc1f2c75,0 0 25px #fc1f2c75
}

.cm-s-synthwave-84 .cm-strong {
    color: #f97e72;
    font-weight: 700
}

.cm-s-synthwave-84 .cm-em {
    color: #f4eee4;
    font-style: italic;
    text-shadow: 0 0 2px #393a33,0 0 8px #f39f0575,0 0 2px #f39f0575
}

.cm-s-synthwave-84 .cm-attribute {
    color: #fff5f6;
    text-shadow: 0 0 2px #000,0 0 10px #fc1f2c75,0 0 5px #fc1f2c75,0 0 25px #fc1f2c75
}

.cm-s-synthwave-84 .cm-link {
    color: #f97e72;
    border-bottom: solid 1px #f97e72
}

.cm-s-synthwave-84 .cm-keyword {
    color: #f4eee4;
    font-style: italic;
    text-shadow: 0 0 2px #393a33,0 0 8px #f39f0575,0 0 2px #f39f0575
}

.cm-s-synthwave-84 .cm-def {
    color: #fdfdfd;
    text-shadow: 0 0 2px #001716,0 0 3px #03edf975,0 0 5px #03edf975,0 0 8px #03edf975
}

.cm-s-synthwave-84 .cm-atom {
    color: #f97e72
}

.cm-s-synthwave-84 .cm-number {
    color: #f97e72
}

.cm-s-synthwave-84 .cm-property {
    color: #fdfdfd;
    text-shadow: 0 0 2px #001716,0 0 3px #03edf975,0 0 5px #03edf975,0 0 8px #03edf975
}

.cm-s-synthwave-84 .cm-qualifier {
    color: #f97e72
}

.cm-s-synthwave-84 .cm-variable {
    color: #f92aad;
    text-shadow: 0 0 2px #100c0f,0 0 5px #dc078e33,0 0 10px #fff3
}

.cm-s-synthwave-84 .cm-variable.cm-highlight {
    text-shadow: 0 0 5px #a46594, 0 0 9px #dc078e9c, 0 0 16px #ffffff7a;
}

.cm-s-synthwave-84 .cm-variable-2 {
    color: #f92aad;
    text-shadow: 0 0 2px #100c0f,0 0 5px #dc078e33,0 0 10px #fff3
}

.cm-s-synthwave-84 .cm-string {
    color: #ff8b39
}

.cm-s-synthwave-84 .cm-string-2 {
    color: #ff8b39
}

.cm-s-synthwave-84 .cm-operator {
    color: #f4eee4;
    text-shadow: 0 0 2px #393a33,0 0 8px #f39f0575,0 0 2px #f39f0575
}

.cm-s-synthwave-84 .cm-meta {
    color: #666666
}

.cm-s-synthwave-84 .cm-comment {
    color: #6d77b3;
    font-style: italic
}

.cm-s-synthwave-84 .cm-error {
    color: #fff5f6;
    text-shadow: 0 0 2px #000,0 0 10px #fc1f2c75,0 0 5px #fc1f2c75,0 0 25px #fc1f2c75
}
