.scroll-container {
    height: 300px;
    overflow: hidden;
    position: relative;
}

.scroll-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    animation: slideDown 60s linear infinite;
}

@keyframes slideDown {
    from {
        top: 0;
    }
    to {
        top: calc(-100% - 2400px);
    }
}
