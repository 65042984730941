div.jsx-1170653115 {
    position: absolute;
    margin: 0px;
    top: -3px;
    left: -9px;
    width: 100%;
    text-align: center
}

input.jsx-1170653115 {
    width: 250px;
    background: none;
    outline: none;
    border: none;
    text-align: center;
    max-width: -webkit-calc(100% - 140px);
    max-width: -moz-calc(100% - 140px);
    max-width: calc(100% - 140px);
    font-size: 14px;
    color: #fff
}