.hand {
    margin:20px;
    display:  grid;
    grid-gap: 0.5rem;
    grid-template-rows: repeat(2, 32px);
    transition: grid-template-rows 0.5s;
}

.hand:hover {
    grid-template-rows: repeat(2, 355px); /*how much should each row move*/
}

.card {
    /*display: grid;*/
    /*grid-template-rows: 30px 60px 30px;*/
    /*grid-template-columns: 35px 80px 35px;*/
    /*align-items: center;*/
    /*justify-items: center;*/

    position: relative;
}

.card:first-child {
    z-index: 3;
}

* {
    box-sizing: border-box;
}
