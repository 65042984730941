.handler.jsx-43165554 {
    z-index: 2;
    position: absolute;
    background-color: #57b5f9;
    top: 56px;
    bottom: 56px;
    right: 56px;
    width: 8px;
    cursor: ew-resize;
    opacity: 0
}

.handler.jsx-43165554:hover {
    opacity: .4
}