.window-controls.jsx-4023755556 {
    margin-top: -24px;
    position: relative;
    top: 34px;
    margin-left: 14px;
    margin-right: 0px;
    z-index: 2;
    text-align: initial
}

.copy-button.jsx-4023755556 {
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 16px
}